function loadBibleGatewayTagger () {
  const bglinksScript = document.createElement("script");
  bglinksScript.setAttribute("src", "https://www.biblegateway.com/public/link-to-us/tooltips/bglinks.js");
  document.body.appendChild(bglinksScript);
  bglinksScript.onload = function() {
	BGLinks.version = "CSB";
	BGLinks.linkVerses();
  };
}

window.delayedScriptLoaders.push(loadBibleGatewayTagger);
